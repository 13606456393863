<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.gnb.group_myinfo.my_group.detailed_group_edit_per_mem_mgmt'),
        url: 'members'
      },
      {
        name: $t('studio.member_manage.member_invite_btn')
      }
    ]"
    :title="$t('studio.member_manage.member_invite_btn')"
  />
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box>
        <h3 class="text-2xl leading-xl font-bold text-on-surface-elevation-2">
          {{ $t('studio.member_manage.member_invite.mem_invite') }}
        </h3>
        <st-form-title
          :formTitle="$t('studio.member_manage.member_invite.mem_invite_link_activation_y_n')"
          class="mt-16"
        />
        <p class="text-xs leading-xs text-placeholder font-regular mt-4">
          {{ $t('studio.member_manage.member_invite.mem_invite_link_activation_guide') }}
        </p>
        <radio-group
          v-model="isLinkEnable"
          :options="isLinkEnableOptions"
          :optionProps="{ size: 'sm', align: 'middle' }"
          class="flex flex-wrap gap-x-24 mt-8"
        />
        <st-form-title
          :formTitle="$t('studio.member_manage.member_invite.mem_invite_link')"
          class="mt-16"
        />
        <div class="flex items-center gap-8">
          <p
            v-if="duration !== DURATION_OPTIONS.NONE"
            class="text-md leading-lg font-regular"
            :class="isLinkExpired ? 'text-system-red' : 'text-on-surface-elevation-3'"
          >
            {{
              isLinkExpired
                ? $t('studio.member_manage.member_invite.mem_invite_link_expired')
                : $t('studio.member_manage.member_invite.link_expiry', {
                  remainingTimeNum: `${$t(`${getLabel(durationOptions, duration)}`)}`
                })
            }}
          </p>
        </div>
        <div
          class="mt-8 flex items-center py-12 px-16 rounded-2xl relative"
          :class="isLinkEnable === Confirmation.NO ? 'bg-[#ECF0F3]' : 'bg-neutral-variant-2'"
        >
          <p
            v-if="isLinkEnable === Confirmation.YES"
            class="text-md font-regular text-on-surface-elevation-2 leading-sm peer"
          >
            {{ link }}
          </p>
          <p v-else class="text-md font-regular text-disabled-variant-1 leading-sm peer">
            {{ $t('studio.member_manage.invite_link_activate_guide') }}
          </p>
          <span
            v-if="isMessageShow"
            class="absolute right-40 h-28 min-w-92 justify-center flex items-center rounded-full bg-neutral-variant-1/90 px-12 text-xs font-medium leading-xs text-white"
          >
            <s-icon icon="ic-v2-control-check-line" />
            {{ $t('studio.member_manage.member_invite.mem_invite_link_copied') }}
          </span>
          <button
            v-if="isLinkEnable === Confirmation.YES"
            type="button"
            class="ml-auto w-20 h-20"
            @click="handleCopy"
          >
            <s-icon
              icon="ic-v2-community-copy-line"
              size="3xl"
              class="text-on-surface-elevation-2 flex"
            />
          </button>
        </div>
        <button
          type="button"
          class="mt-16 font-medium text-md text-brand-primary leading-sm"
          @click="generateLink"
        >
          {{ $t('studio.member_manage.member_invite.link_regen_btn') }}
        </button>
        <div class="mt-24 pt-24 border-t-1 border-solid border-border">
          <h3 class="text-2xl leading-xl font-bold text-on-surface-elevation-2">
            {{ $t('studio.member_manage.member_invite.mem_invite_link_advanced_settings') }}
          </h3>
        </div>
        <st-form-title
          :formTitle="$t('studio.member_manage.member_invite.mem_invite_set_link_expiry')"
          class="mt-16"
        />
        <p class="text-xs leading-xs text-placeholder font-regular mt-4">
          {{ $t('studio.member_manage.member_invite.mem_invite_set_link_expiry_guide') }}
        </p>
        <div class="relative mt-8">
          <dropdown
            :key="duration"
            v-model="duration"
            :options="durationOptions"
            :disabled="isLinkEnable === Confirmation.NO"
            :dropdownProps="{
              size: 'lg',
              variant: 'line',
              distance: 0,
              offset: [0, 0],
              placement: 'bottom-start',
              class: 'w-full'
            }"
            closeOnClick
          >
            <template #dropdown-button="{ selectedOption }">
              {{ `${$t(selectedOption.label)}` }}
            </template>
          </dropdown>
        </div>
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="handleSubmit">
          {{ $t('studio.common.popup_case_save_btn') }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="$t('studio.member_manage.member_invite.mem_invite_link_guide')"
        numberValue="01"
      >
        {{ $t('studio.member_manage.member_invite.mem_invite_link_guide1') }}
      </right-wing-item>
      <right-wing-item
        :rightWingTitle="
          $t('studio.member_manage.member_invite.mem_invite_link_advanced_setting_guide')
        "
        numberValue="02"
        class="mt-24"
      >
        {{ $t('studio.member_manage.member_invite.mem_invite_link_advanced_setting_guide1') }}
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script lang="ts" setup>
import { useRequestURL } from 'nuxt/app';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import {
  applyInvitationApi,
  fetchInvitationLinkApi,
  regenerateInvitationApi
} from '@/apis/member.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTitle from '@/components/common/st-title.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { STATUS_CODE } from '@/constants/error.const';
import { Confirmation } from '@/enums/common.enum';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { InvitationLinkResponse } from '@/types/member/member-response.type';
import { showConfirmLeaveDialog } from '@/utils/common.util';

definePageMeta({ middleware: 'check-menu-writable-middleware' });

const DURATION_OPTIONS = {
  '30-MINUTES': '30-MINUTES',
  '1-HOURS': '1-HOURS',
  '6-HOURS': '6-HOURS',
  '12-HOURS': '12-HOURS',
  '1-DAYS': '1-DAYS',
  '7-DAYS': '7-DAYS',
  NONE: 'NONE'
};

const app = useApp();
const { t, locale } = useI18n();

const route = useRoute();
const url = useRequestURL();

const groupId = route.params.groupId as string;

const isMessageShow = ref<boolean>(false);

const isLinkEnable = ref<Confirmation.YES | Confirmation.NO>(Confirmation.NO);
const duration = ref<string>(DURATION_OPTIONS['7-DAYS']);

const inviteLinkInfo = ref<InvitationLinkResponse>();

const isLinkEnableOptions: FormOption[] = [
  {
    value: Confirmation.YES,
    label: 'studio.member_manage.member_invite.mem_invite_link_activation_y'
  },
  {
    value: Confirmation.NO,
    label: 'studio.member_manage.member_invite.mem_invite_link_activation_n'
  }
];

const durationOptions: FormOption[] = [
  {
    value: DURATION_OPTIONS['30-MINUTES'],
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_30m'
  },
  {
    value: DURATION_OPTIONS['1-HOURS'],
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_1h'
  },
  {
    value: DURATION_OPTIONS['6-HOURS'],
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_6h'
  },
  {
    value: DURATION_OPTIONS['12-HOURS'],
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_12h'
  },
  {
    value: DURATION_OPTIONS['1-DAYS'],
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_1d'
  },
  {
    value: DURATION_OPTIONS['7-DAYS'],
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_def_7d_'
  },
  {
    value: DURATION_OPTIONS.NONE,
    label: 'studio.member_manage.member_invite.mem_invite_set_link_expiry_n'
  }
];

const isLinkExpired = computed<boolean>(() => {
  if (!inviteLinkInfo.value) {
    return false;
  }

  return inviteLinkInfo.value.expiredYn === Confirmation.YES;
});

const link = computed(() => {
  return `${url.origin}/${locale.value}/group/register?invitedId=${inviteLinkInfo.value?.invitationLinkId}`;
});

const getLabel = (options: FormOption[], value: string) => {
  const label = options.find((option: FormOption) => option.value === value)?.label;

  return label ?? '';
};

// Copy to clipboard
const handleCopy = () => {
  try {
    if (!link.value) {
      return;
    }

    const tempElement = document.createElement('textarea');
    tempElement.innerText = link.value;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);
    // Execute the copy command
    const successful = document.execCommand('copy');

    isMessageShow.value = successful;
    setTimeout(() => {
      isMessageShow.value = false;
    }, 2000);

    if (!successful) {
      // console.error('Unable to copy');
    }
  } catch (err) {
    // console.error('Unable to copy', err);
  }
};

const generateLink = async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  const regenerate = await showConfirm({
    cancelVariant: 'outline',
    confirmLabel: t('studio.member_manage.member_invite.link_regen_cf_btn'),
    content: t('studio.member_manage.member_invite.link_regen_guide')
  });

  if (regenerate && inviteLinkInfo.value) {
    try {
      const res = await regenerateInvitationApi({
        groupId,
        invitationLinkId: inviteLinkInfo.value.invitationLinkId
      });

      if (!res) {
        return;
      }

      inviteLinkInfo.value = res;
      isLinkEnable.value = Confirmation.YES;
      if (inviteLinkInfo.value.expirePeriodType !== DURATION_OPTIONS.NONE) {
        duration.value = `${inviteLinkInfo.value.expirePeriodAmount}-${inviteLinkInfo.value.expirePeriodType}`;
      } else {
        duration.value = DURATION_OPTIONS.NONE;
      }

      await showConfirm({
        isCancelButtonVisible: false,
        content: t('studio.member_manage.member_invite.link_regen_done')
      });

      await handleSubmit(false);
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      if (errorCode === STATUS_CODE.READ_WRITE_ACCESS_DENIED) {
        // console.error('error', error);
      }
    }
  }
};

const handleSubmit = async (isPopupShown: boolean = true) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  if (!inviteLinkInfo.value) {
    return;
  }

  let expirePeriodAmount;
  let expirePeriodType = DURATION_OPTIONS.NONE;

  if (duration.value !== DURATION_OPTIONS.NONE) {
    expirePeriodAmount = Number(duration.value.split('-')[0]);
    expirePeriodType = duration.value.split('-')[1];
  }

  const data = {
    activeYn: isLinkEnable.value,
    expirePeriodAmount,
    expirePeriodType
  };

  await applyInvitationApi({
    groupId,
    invitationLinkId: inviteLinkInfo.value.invitationLinkId,
    data
  });

  if (isPopupShown) {
    await showAlert({
      content: t('studio.common.popup_case_d_complete_save')
    });
  }
};

const init = async () => {
  try {
    const res = await fetchInvitationLinkApi(groupId);

    if (res) {
      inviteLinkInfo.value = res;
      isLinkEnable.value = inviteLinkInfo.value.activeYn;

      if (inviteLinkInfo.value.expirePeriodType !== DURATION_OPTIONS.NONE) {
        duration.value = `${inviteLinkInfo.value.expirePeriodAmount}-${inviteLinkInfo.value.expirePeriodType}`;

        return;
      }
      duration.value = DURATION_OPTIONS.NONE;
    }
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.READ_WRITE_ACCESS_DENIED) {
      // console.error('error', error);
    }
  }
};

init();

onBeforeRouteLeave(async () => {
  return await showConfirmLeaveDialog();
});
</script>
